


































import {Component, Watch, Prop, Vue} from 'vue-property-decorator';
import {upload} from '@/api/file/upload';
import CourseApi from '@/api/course/course';
import {ListResultDto} from '@/framework/models/ResultDto';
import {CourseImgDto} from '@/framework/models/CourseImg';
import AdManagementApi from '@/api/system/AdManagement'

@Component({})
export default class CourseImg extends Vue {
  @Prop({default: () => false, required: true}) private visible!: boolean;
  @Prop({}) private otherId!: string;
  @Prop({}) private imgType!: string

  private dialogImageUrl = '';
  private dialogVisible = false;
  private fileList = new Array<any>();
  private disabled = false;
  private currentId = '';
  private currentImgType = ''
  private api = new CourseApi();
  private imgDto: ListResultDto<CourseImgDto> = {items: new Array<CourseImgDto>()};
  private imgApi = new AdManagementApi()

  @Watch('otherId', {immediate: true})
  public onCourseId(vl: any, ol: any) {
    if (vl != ol) {
      this.currentId = vl;
    }
  }

  @Watch('imgType', {immediate: true})
  public onImgType(vl: any, ol: any) {
    if (vl != ol) {
      this.currentImgType = vl;
    }
  }

  private close() {
    this.fileList = [];
    this.$emit('handleClose');
  }

  private async mounted() {
    await this.getImgs();
  }

  private async getImgs() {
    if (this.currentId) {
      if (this.currentImgType === 'course') {
        this.imgDto = await this.api.getCourseImgsAsync(this.currentId);
      } else if (this.currentImgType === 'shop') {
        this.imgDto = await this.imgApi.getImgAsync(this.currentId);
      }
      if (this.imgDto.items.length > 0) {
        this.imgDto.items.forEach((value: CourseImgDto) => {
          this.fileList.push({
            id: value.id,
            name: value.name,
            url: `${value.fullName}`
          });
        });
      }
    }
  }

  private handelConfirm() {
    const fileListTemp = new Array<any>();
    if (this.fileList.length > 0 && this.currentId) {
      this.fileList.forEach((value, index) => {
        if (value.status === 'ready') {
          const uploadForm = new FormData();
          uploadForm.append('file', this.fileList[index].raw);

          upload(uploadForm).then((res: any) => {
            if (res) {
              fileListTemp.push(res.fullName);
            }
          });
        }
      });
    }

    setTimeout(async () => {
      await this.save(fileListTemp);
    }, 800);
  }

  private async save(imgs: any) {
    if (this.currentImgType === 'course') {
      await this.api.createCourseImg(this.currentId, imgs);
    } else if (this.currentImgType === 'shop') {
      await this.imgApi.createImgAsync(this.currentId, imgs);
    }
    this.$notify({
      message: '上传成功',
      type: 'success',
      title: ''
    });
    this.close();
  }

  private async handleRemove(file: any) {
    if (this.fileList.length > 0) {
      const index = this.fileList.findIndex((x) => x.uid === file.uid);
      this.fileList.splice(index, 1);

      if (file.id) {
        if (this.currentImgType === 'course') {
          await this.api.deleteCourseImg(file.id);
        }
      }
    }
  }

  private handlePictureCardPreview(file: any) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }

  private handleChange(file: any, fileList: any) {
    this.fileList = fileList;
  }
};

import request from '@/utils/request'
import ReadOnlyAppService from '@/framework/ReadOnlyAppService'
import {CourseOrderDto, GetListCourseOrderDto} from '@/framework/models/CourseOrder'
import {Guid} from 'guid-typescript'

export default class CourseOrderApi extends ReadOnlyAppService <CourseOrderDto, CourseOrderDto, Guid, GetListCourseOrderDto> {
  constructor() {
    super(`/keep-fit/courseOrder`);
  }

  public async payment(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/payment`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async getCourseOrderByCourseIdAsync(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/orders-by-courseId`,
        method: 'get',
        params: {
          courseId
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async updateOrderCourseStateAsync(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/order-course-state`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async queryOrder(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/query-order`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async refundAsync(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/refund`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async refundQueryAsync(id: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/refund-query`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }

  public async getCoursePtOrderByCourseIdAsync(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/orders-by-pt-courseId`,
        method: 'get',
        params: {
          courseId
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    })
  }
}

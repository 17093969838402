import {ExtensibleEntityDto} from './ExtensibleEntityDto';
import {PagedAndSortedResultRequestDto} from './PagedAndSortedResultRequestDto';
import {Guid} from 'guid-typescript';

export interface CourseDto extends ExtensibleEntityDto <Guid> {
  name: string
  category: number
  helpline: string
  originalPrice: number
  price: number
  limitNum: number
  courseTime: string
  openingDate: string
  startDateTime: string
  endDateTime: string
  remark: string,
  coachIds: [],
  courseState: number
}

export class CreateOrUpdateCourseDto {
  public name: string = '';
  public category: number | undefined;
  public helpline: string = '';
  public originalPrice: number | undefined;
  public price: number | undefined;
  public limitNum: number | undefined;
  public openingDate: string = '';
  public courseTime: string = '';
  public startDateTime: string = '';
  public endDateTime: string = '';
  public remark: string = '';
  public coachIds = new Array();
  public courseState: number = 0;
}

export class GetListCourseDto extends PagedAndSortedResultRequestDto {
  public filter: string = '';
  public category?: number = undefined;
  public openDate?: string = ''
}

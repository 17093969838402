import request from '@/utils/request';

const url = `/keep-fit/file-upload`;

export function upload(data: any) {
  return new Promise<any>((resolve, reject) => {
    request({
      url,
      method: 'post',
      data
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  });

}

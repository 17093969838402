














































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {CreateOrUpdateCourseDto} from '@/framework/models/Course';
import {ListResultDto} from '@/framework/models/ResultDto';
import {CoachDto} from '@/framework/models/Coach';
import CourseApi from '@/api/course/course';
import {getCoachList} from '@/api/course/coach';
import {Guid} from 'guid-typescript';

@Component({})
export default class CourseAdd extends Vue {
  @Prop({default: false}) private visible!: boolean
  @Prop({default: ''}) private title!: string
  @Prop({default: 1}) private cateType!: number
  @Prop({default: ''}) private courseId!: Guid

  private api = new CourseApi()
  private coachOptions: ListResultDto<CoachDto> = {items: new Array<CoachDto>()};
  private value1 = new Array();
  private dataForm: CreateOrUpdateCourseDto = {
    name: '',
    category: this.cateType,
    helpline: '',
    originalPrice: undefined,
    price: undefined,
    limitNum: undefined,
    courseTime: '',
    openingDate: '',
    startDateTime: '',
    endDateTime: '',
    remark: '',
    coachIds: [],
    courseState: 0
  };
  private rules = {
    name: [{
      required: true,
      message: '课程名称不能为空',
      trigger: 'blur'
    }],
    category: [{
      required: true,
      message: '课程分类不能为空',
      trigger: 'blur'
    }],
    originalPrice: [{
      required: true,
      message: '原价不能为空',
      trigger: 'blur'
    }],
    price: [{
      required: true,
      message: '现价不能为空',
      trigger: 'blur'
    }]
  };

  private async mounted() {
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
    if (this.dataForm.category === 1) {
      this.value1 = ['09:00', '10:00']
    }
    await this.getCoachListAsync()
    await this.getInfo()
  }

  private async getCoachListAsync() {
    this.coachOptions = await getCoachList();
  }

  private async getInfo() {
    if (this.title === '编辑' && this.courseId) {
      const result = await this.api.getAsync(this.courseId)
      if (result) {
        this.dataForm = Object.assign({coachIds: []}, result);
        if (result.startDateTime && result.endDateTime) {
          this.value1 = [result.startDateTime, result.endDateTime];
        }

        const result2 = await this.api.getCoachesAsync(result.id);
        this.dataForm.coachIds = result2.items.map((v) => v.id);
      }
    }
  }


  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (this.value1) {
        this.dataForm.endDateTime = `${this.value1[1]}`;
        this.dataForm.startDateTime = `${this.value1[0]}`;
      }

      if (this.title === '新增') {
        await this.creating(this.dataForm);
      } else if (this.title === '编辑') {
        await this.editing(this.dataForm);
      }
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    this.handleClose();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    this.handleClose();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}

import request from '@/utils/request';
import CrudAppService from '@/framework/CrudAppService';
import {CourseDto, CreateOrUpdateCourseDto, GetListCourseDto} from '@/framework/models/Course';
import {Guid} from 'guid-typescript';
import {ListResultDto} from '@/framework/models/ResultDto';
import {CoachDto} from '@/framework/models/Coach';
import {CourseImgDto} from '@/framework/models/CourseImg';

export default class CourseApi extends CrudAppService <CourseDto, CourseDto, Guid, GetListCourseDto,
  CreateOrUpdateCourseDto, CreateOrUpdateCourseDto> {
  constructor() {
    super(`/keep-fit/course`);
  }

  public async getCoachesAsync(id: Guid): Promise<ListResultDto<CoachDto>> {
    return new Promise<ListResultDto<CoachDto>>((resolve, reject) => {
      request({
        url: `${this.url}/${id}/coachs`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }

  public async createCourseImg(id: string, imgs: any) {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/imgs`,
        method: 'post',
        data: {
          imgs
        }
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }

  public async getCourseImgsAsync(id: string): Promise<ListResultDto<CourseImgDto>> {
    return new Promise<ListResultDto<CourseImgDto>>((resolve, reject) => {
      request({
        url: `${this.url}/${id}/imgs`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }

  public async deleteCourseImg(id: string) {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/imgs`,
        method: 'delete'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }

  public async openCourseAsync(id: string) {
    return new Promise((resolve, reject) => {
      request({
        url: `${this.url}/${id}/open-course`,
        method: 'post'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }
}

import request from '@/utils/request';
import CrudAppService from '@/framework/CrudAppService';
import {ListResultDto} from '@/framework/models/ResultDto';
import {CoachDto, CreateOrUpdateCoachDto, GetListCoachDto} from '@/framework/models/Coach';
import {Guid} from 'guid-typescript';

const url = `/keep-fit/coach`;

export default class CoachApi extends CrudAppService <CoachDto, CoachDto, Guid, GetListCoachDto,
  CreateOrUpdateCoachDto, CreateOrUpdateCoachDto> {
  constructor() {
    super(url);
  }
}

export function getCoachList() {
  return new Promise<ListResultDto<CoachDto>>((resolve, reject) => {
    request({
      url: `${url}/getListByState`,
      method: 'get'
    }).then((res) => resolve(res.data)).catch((err) => reject(err));
  });
}

